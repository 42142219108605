<template>
    <v-app :dark="dark" id="blueframe">
        <v-content>
            <ConfigRow v-if="'dev' === mode" />
            <div id="blueframe-inner">
                <div v-if="configLoaded">
                    <LinksRow v-if="hasLinks && 'portal' !== mode" />
                    <LogoRow v-if="hasLogo && 'portal' !== mode" />
                    <div class="layout-row">
                        <SearchRow v-show="viewPlayer" v-if="!searchOmni && fullSearch" />
                        <OmniSearch v-if="searchOmni && fullSearch" />
                        <BreadCrumbRow />
                        <PlayerRow v-show="viewPlayer" v-if="!forceSelect" />
                        <SearchRow v-show="viewPlayer" v-if="!searchOmni && !fullSearch" />
                        <OmniSearch v-if="searchOmni && !fullSearch" />
                    </div>
                    <LayoutRow />
                    <SponsorsRow v-if="hasSponsors" :row="{ title: 'Sponsors', sponsorsParams: { sponsors: sponsors } }" />
                    <HudlRow v-if="!whiteLabeled && 'portal' !== mode" />
                </div>
                <div v-else>
                    &nbsp;
                </div>
            </div>
            <BottomNavRow v-if="layoutLoaded" v-show="mobile" />
        </v-content>
    </v-app>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import ConfigRow from './components/ConfigRow.vue'
import LinksRow from './components/LinksRow.vue'
import LogoRow from './components/LogoRow.vue'
import OmniSearch from './components/OmniSearch.vue'
import BreadCrumbRow from './components/BreadCrumbRow.vue'
import PlayerRow from './components/PlayerRow.vue'
import SearchRow from './components/SearchRow.vue'
import SponsorsRow from './components/SponsorsRow.vue'
import LayoutRow from './components/LayoutRow.vue'
import HudlRow from './components/HudlRow.vue'
import BottomNavRow from './components/BottomNavRow.vue'

export default {
    name: 'App',
    components: {
        ConfigRow,
        LinksRow,
        LogoRow,
        OmniSearch,
        BreadCrumbRow,
        PlayerRow,
        SearchRow,
        SponsorsRow,
        LayoutRow,
        HudlRow,
        BottomNavRow
    },
    props: {
        colorprimary: {
            type: String,
            default: null
        },
        colorsecondary: {
            type: String,
            default: null
        },
        domain: {
            type: String,
            default: null
        },
        excludebroadcasts: {
            type: String,
            default: null
        },
        excludesections: {
            type: String,
            default: null
        },
        excludesites: {
            type: String,
            default: null
        },
        forceselect: {
            type: String,
            default: null
        },
        fullsearch: {
            type: String,
            default: null
        },
        omni: {
            type: String,
            default: null
        },
        portalbg: {
            type: String,
            default: null
        },
        portalbroadcast: {
            type: String,
            default: null
        },
        portalconfig: {
            type: String,
            default: null
        },
        portalmode: {
            type: String,
            default: null
        },
        portalnav: {
            type: String,
            default: null
        },
        portaloffset: {
            type: String,
            default: null
        },
        portalsections: {
            type: String,
            default: null
        },
        portalsite: {
            type: String,
            default: null
        },
        portalsponsors: {
            type: String,
            default: null
        },
        portaltheme: {
            type: String,
            default: null
        },
        sharedsites: {
            type: String,
            default: null
        },
        siteids: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            defaultConfig: {"title":"Hudl Web App","primaryThemeColor":"#000000","secondaryThemeColor":"#ffffff","vCloud":{"domain":"vcloud.hudl.com","siteIds":[]},"layout":{"type":"image","rows":[{"type":"settings","title":"Search","settingsParams":{"filters":{"showSitesFilter":true,"showSectionsFilter":true,"showDateFilter":true,"showTitleFilter":true}}},{"type":"broadcast","title":"Live / Upcoming","mobileTitle":"Live / Upcoming","broadcastSearchParams":{"sortBy":"date","sortDir":"asc","viewerStatus":3}},{"type":"broadcast","title":"Recent","mobileTitle":"Recent","broadcastSearchParams":{"sortBy":"date","sortDir":"desc","viewerStatus":4}},{"type":"site","title":"Sites","siteSearchParams":{"defaultSiteIds":[],"appendFavoriteSites":true,"sortBy":"title","sortDir":"asc"}}]},"siteLayout":{"type":"image","rows":[{"type":"broadcast","title":"Live / Upcoming","mobileTitle":"Live / Upcoming","broadcastSearchParams":{"sortBy":"date","sortDir":"asc","viewerStatus":3}},{"type":"broadcast","title":"Recent","mobileTitle":"Recent","broadcastSearchParams":{"sortBy":"date","sortDir":"desc","viewerStatus":4}}]}},
            queryconfig: null,
        }
    },
    computed: {
        ...mapState([
            'broadcast',
            'broadcastEmpty',
            'broadcastReady',
            'config',
            'configLink',
            'configLoaded',
            'configParent',
            'dark',
            'firstLoad',
            'forceSelect',
            'fullSearch',
            'hasLinks',
            'hasLogo',
            'hasSponsors',
            'layoutLoaded',
            'mode',
            'portalNavigation',
            'searchOmni',
            'selectedSite',
            'setUrl',
            'sponsors',
            'viewPlayer',
            'sites',
            'whiteLabeled'
        ]),
        ...mapGetters([
            'mobile'
        ])
    },
    methods: {
        handleResize() {
            this.$store.commit('SET_WIDTH', document.getElementById('blueframe-inner').getBoundingClientRect().width)
        },
        handleBrowserNav() {
            this.$store.commit('LOAD_STATE', location.href)
        },
        isEqual(objA, objB) {
            let aProps = Object.getOwnPropertyNames(objA)
            let bProps = Object.getOwnPropertyNames(objB)
            if (aProps.length != bProps.length) {
                return false
            }
            for (var i = 0; i < aProps.length; ++i) {
                let propName = aProps[i]
                if (objA[propName] !== objB[propName]) {
                    return false
                }
            }
            return true
        }
    },
    watch: {
        configLoaded() {
            this.handleResize()
        },
        setUrl() {
            let query = JSON.parse(JSON.stringify(this.$route.query))

            // Check config
            if (typeof(query.C) === 'undefined') {
                query.C = this.configLink
            } else if (query.C !== this.configLink) {
                query.C = this.configLink
            } else {
                // no change
            }
            if ('' === query.C) {
                delete query.C
            }

            // Check site
            if (typeof(query.S) !== 'undefined') {
                if (this.selectedSite === null) {
                    delete query.S
                } else if (query.S !== this.sites[this.selectedSite].slug) {
                    if (!this.portalNavigation) {
                        query.S = this.sites[this.selectedSite].slug
                    }
                } else {
                    //no change
                }
            } else {
                if (this.selectedSite !== null) {
                    if (!this.portalNavigation) {
                        query.S = this.sites[this.selectedSite].slug
                    }
                } else {
                    // no change
                }
            }

            let save_all = false

            // Don't set the Broadcast query param on initial page load. Only set it for subsequent navigations.
            if (!this.firstLoad) {
                // Check broadcast
                if (typeof(query.B) !== 'undefined') {
                    if (this.broadcastEmpty) {
                        delete query.B
                    } else if (query.B !== this.broadcast.id) {
                        query.B = this.broadcast.id
                    } else {
                        // no change
                    }
                } else {
                    if (!this.broadcastEmpty) {
                        query.B = this.broadcast.id
                    } else {
                        // no change
                    }
                }
            } else {
                if (typeof(query.B) !== 'undefined') {
                    delete query.B
                }
                this.$store.commit('SET_FIRSTLOAD', false)
                save_all = true
            }

            if (!this.isEqual(query, this.$route.query)) {
                this.$router.push({ query: query})
            }

            this.$store.commit('SAVE_STATE', {id:location.href, all:save_all})
        }
    },
    created() {
        window.addEventListener('resize', this.handleResize)
        window.addEventListener('popstate', this.handleBrowserNav)
    },
    mounted() {
        let props = JSON.parse(JSON.stringify(this.$props))

        if (null !== props.portalconfig) {
            let url = props.portalconfig.replace(/^(https:|http:)/, '')
            let urlParts = url.split('/')
            urlParts.pop()
            this.$store.commit('SET_CONFIGPARENT', urlParts.join('/')+'/')
        }

        if (this.$route.query.C) {
            this.queryconfig = this.$route.query.C
            if (!/(.json)$/.test(this.queryconfig)) {
                this.queryconfig += '/config.json'
            }
            if (!/^(https|http|\/\/)/.test(this.queryconfig)) {
                let configParts = this.queryconfig.split('/')
                let parentParts = this.configParent.split('/')
                parentParts.pop()
                while (configParts.length > 0 && parentParts.length > 0) {
                    if ('..' === configParts[0]) {
                        configParts.shift()
                        parentParts.pop()
                    } else {
                        break
                    }
                }
                this.queryconfig = parentParts.join('/') + '/' + configParts.join('/')
            }
        }
        if (this.$route.query.S) {
            props.portalsite = this.$route.query.S
        }
        if (this.$route.query.B) {
            props.portalbroadcast = this.$route.query.B
        }

        this.$store.commit('SET_PROPS', props)
        this.$store.dispatch('LOAD_PROPS')

        if (null !== this.queryconfig) {
            this.$store.dispatch('GET_CONFIG', {url:this.queryconfig, attempt:1})
        } else if (null !== props.portalconfig) {
            this.$store.dispatch('GET_CONFIG', {url:props.portalconfig, attempt:1})
        } else if (typeof(window.config) !== 'undefined') {
            this.$store.commit('SET_CONFIG', window.config)
            if (typeof(this.config.vCloud.siteIds) !== 'undefined') {
                this.$store.dispatch('LOAD_CONFIG')
            } else {
                this.$store.dispatch('GET_WIDGET')
            }
        } else if (null !== props.siteids) {
            let siteIds = props.siteids.split(',')
            this.defaultConfig.vCloud.siteIds = siteIds
            if (null !== props.domain) {
                this.defaultConfig.vCloud.domain = props.domain
            }
            if (siteIds.length == 1) {
                // remove the site row
                this.defaultConfig.layout.rows.splice(3,1)
            }
            this.$store.commit('SET_CONFIG', this.defaultConfig)
            this.$store.dispatch('LOAD_CONFIG')
        } else {
            this.$store.commit('SET_MODE', 'dev')
            this.$store.commit('SET_BROADCAST', {})
        }

        this.$store.dispatch('LOAD_POSITION', 'blueframe')
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
        window.removeEventListener('popstate', this.handleBrowserNav)
    }
}
</script>

<style>
#blueframe {
    font-family: 'Helvetica', sans-serif;
    font-weight: 400;
    font-style: normal;
    padding:12px 0;
}
#blueframe-inner,
.inner-div {
    width:100%;
    margin:auto;
}
@media (min-width: 576px) {
    #blueframe {
        padding:12px;
    }
}
@media (min-width:768px) {
    #blueframe-inner,
    .inner-div {
        width:90%;
    }
}
@media (min-width:992px) {
    #blueframe-inner,
    .inner-div {
        width:85%;
    }
}
@media (min-width:1280px) {
    #blueframe-inner,
    .inner-div {
        width:80%;
    }
}
.flex {
    flex: initial !important;
}
.primary-bg {
    color: white !important;
    background-color: var(--v-primary-base) !important;
}
.darken1-bg {
    color: white !important;
    background-color: var(--v-primary-darken1) !important;
}
.v-card__text {
    width: unset !important;
}
.v-list__tile {
    color: inherit !important;
}
.v-btn {
    text-transform: none !important;
}
.v-input--selection-controls:not(.v-input--hide-details) .v-input__slot {
    margin-bottom: 0 !important;
}
.no-glow,
.no-glow:hover,
.no-glow:focus,
.no-glow:active {
    box-shadow: unset !important;
}
.layout-row {
    margin-bottom: 36px;
}
.row-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    text-align: left;
    margin-bottom: 20px;
}
.pop-up-title {
    font-size: 22px;
    line-height: 26px;
}
.grid-layout {
    display: grid;
    grid-gap: 12px;
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
}
.cell-link {
    display: block;
    cursor: pointer;
}
.cell-link:hover,
button:hover,
li:hover {
    box-shadow: 0 0 4px 3px var(--v-primary-lighten4);
}
.cell-link:focus,
button:focus,
li:focus {
    outline: none !important;
    box-shadow: 0 0 4px 3px var(--v-primary-lighten4);
}
.cell-link:active,
button:active,
li:active {
    box-shadow: 0 0 4px 3px var(--v-primary-lighten4);
}
.cell-title {
    font-weight: 400;
    font-size: 14px;
    padding: 16px;
}
.truncate {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
  overflow: hidden;
  hyphens: auto;
}
.truncate-two {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
  overflow: hidden;
  hyphens: auto;
}
.v-expansion-panel__header {
    padding:12px;
}
.mobile-padding {
    padding: 0 8px;
}
@media (min-width: 576px) {
    .mobile-padding {
        padding: 0;
    }
}
</style>